import * as React from 'react'
import styled from 'styled-components'
import { OUTER_SHADOW } from '../utils/constant'

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

interface Props {
    answer: ChallengeResponse
    user: JourneyUser
}

const Answer = ({ answer, user }: Props) => {
    return (
        <Container>
            <User src={user.picture} />
            <AnswerContainer>
                {answer.content && <AnswerContent>{answer.content}</AnswerContent>}
                {answer.picture && (
                    <Zoom>
                        <AnswerPicture src={answer.picture} loading="lazy"/>
                    </Zoom>
                )}
            </AnswerContainer>
        </Container>
    )
}

export default Answer

const Container = styled.div`
    display: flex;
    align-items: center;
    justicy-content: center;
`
const USER_PICTURE_SIZE = 30
const User = styled.img`
    min-height: ${USER_PICTURE_SIZE}px;
    min-width: ${USER_PICTURE_SIZE}px;
    max-height: ${USER_PICTURE_SIZE}px;
    max-width: ${USER_PICTURE_SIZE}px;
    border-radius: 100%;
    margin-right: 20px;
    object-fit: cover;
    ${OUTER_SHADOW}
`
const AnswerContainer = styled.div`
    display: flex;
    align-items: center;
    justicy-content: center;

    padding: 10px;
    ${OUTER_SHADOW}
    border-radius: 10px;
    background-color: #5fc9f8;
`
const ANSWER_PICTURE_CONTAINER = 50
const AnswerPicture = styled.img`
    height: ${ANSWER_PICTURE_CONTAINER}px;
    width: ${ANSWER_PICTURE_CONTAINER}px;
    object-fit: cover;
    cursor: pointer;
    ${OUTER_SHADOW}
`
const AnswerContent = styled.div`
    font-weight: 500;
    color: #fff;
    margin: 0 10px;
`
