import * as React from 'react'
import styled from 'styled-components'

import { LatLng } from 'leaflet'
import * as turf from '@turf/turf'
import CityMap from '../CityMap'

import Button from '../Button'
import { useAlert } from 'react-alert'
import useApi from '../../utils/webservices/useApi'
import { isMobile } from 'react-device-detect'

import useReducer from '../../utils/store/useReducer'
import * as AccountStore from '../../utils/store/account'

interface Props {
    step: Step
    end: City
    arrivals: Arrival[]
    fetchData: () => void
}

const DEFAULT_ZOOM = 11
const DEFAULT_LOC = { lat: 48.864716, lng: 2.349014 }

const StepLocation = ({ step, end, arrivals, fetchData }: Props) => {
    const Alert = useAlert()
    const webservices = useApi()
    const { me } = useReducer(AccountStore.store, (s) => s)

    const [userLocation, setUserLocation] = React.useState<LatLng | undefined>()
    const [userInsideZone, setUserInsideZone] = React.useState<boolean>(false)

    const userAlreadyArrived = !!arrivals.find((a) => !!a.team.users.find((u) => u.id === me?.id))
    const cityPolygon = JSON.parse(end.jsonFile).geojson.coordinates
    const isMarkerInsidePolygon = (lat: number, lng: number, poly: any) => {
        // Créer un point GeoJSON à partir de la position utilisateur
        const userPoint = turf.point([lng, lat])
        const polypoly = turf.polygon(poly)
        // Vérifier si le point est dans le polygone
        const inside = turf.booleanPointInPolygon(userPoint, polypoly)
        return inside
    }

    React.useEffect(() => {
        if (userLocation) {
            const inside = isMarkerInsidePolygon(userLocation.lat, userLocation.lng, cityPolygon)
            setUserInsideZone(inside)
        }
    }, [userLocation])

    return (
        <Container>
            <MapCard>
                <CityMap
                    jsonFile={end.jsonFile}
                    height={isMobile ? 450 : 600}
                    userLocation={userLocation}
                    setUserLocation={setUserLocation}
                />
            </MapCard>
            {!userAlreadyArrived && userLocation && userInsideZone && (
                <ButtonContainer>
                    <Button
                        type="primary"
                        status="active"
                        onclick={() => {
                            webservices.steps
                                .createArrival(step.id, {
                                    latitude: userLocation.lat,
                                    longitude: userLocation.lng,
                                })
                                .then(() => {
                                    Alert.success('Ton arrivée à bien été prise en compte !')
                                    fetchData()
                                })
                                .catch(() => Alert.console.error('Un problème est survenu..'))
                        }}
                        label="Je suis arrivé !"
                        position="RIGHT"
                    />
                </ButtonContainer>
            )}
        </Container>
    )
}

export default StepLocation

const Container = styled.div`
    margin-top: 15px;
`
const MapCard = styled.div`
    padding: ${isMobile ? '15px' : '15px'};
    margin: ${isMobile ? '0px 15px' : '20px'};
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    display: flex;
`
const ButtonContainer = styled.div`
    display: flex;
    margin: 0 15px;
`
