import * as React from 'react'

import styled from 'styled-components'

import { isAfter } from 'date-fns'
import { useNavigate } from 'react-router-dom'

import useApi from '../utils/webservices/useApi'
import Loading from '../components/Loading'
import NoItem from '../components/NoItem'

interface Props {}

const Steps = ({}: Props) => {
    const webservices = useApi()

    const [status, setStatus] = React.useState<ScreenStatus>('loading')

    const [steps, setSteps] = React.useState<Step[]>([])
    const [cities, setCities] = React.useState<City[]>([])

    const navigate = useNavigate()

    React.useEffect(() => {
        Promise.all([
            webservices.journeys.getAvailableSteps(),
            webservices.journeys.getAvailableCities(),
        ])
            .then(([s, c]) => {
                setSteps(
                    s.sort((a, b) => {
                        const AendCity = c.cities.find((city) => city.id === a.endCityId)
                        const BendCity = c.cities.find((city) => city.id === b.endCityId)
                        a.title.localeCompare(b.title)
                        return AendCity && BendCity
                            ? isAfter(AendCity.startDate, BendCity.startDate)
                                ? 1
                                : -1
                            : 1
                    })
                )
                setCities(c.cities)
                setStatus('fetched')
            })
            .catch(() => setStatus('error'))
    }, [])

    if (status === 'loading') {
        return <Loading type="transparent" />
    }

    if (steps.length === 0 || status === 'error') {
        return <NoItem />
    }

    const getCity = (cityId: string) => cities.find((city) => city.id === cityId)

    return (
        <Container>
            <StepGrid>
                {steps.map((step) => {
                    const startCity = getCity(step.startCityId)
                    const endCity = getCity(step.endCityId)

                    return (
                        <StepCard key={step.id} onClick={() => navigate(`/steps/${step.id}`)}>
                            <CityImage src={endCity?.picture || ''} alt={endCity?.name} />
                            <CityInfo>
                                <StepTitle>{step.title}</StepTitle>
                                <CityNames>
                                    <CityName>{startCity?.name}</CityName>
                                    <Arrow> → </Arrow>
                                    <CityName>{endCity?.name}</CityName>
                                </CityNames>
                            </CityInfo>
                        </StepCard>
                    )
                })}
            </StepGrid>
        </Container>
    )
}

export default Steps

const Container = styled.div`
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
        padding: 15px;
    }
`

const StepGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    width: 100%;
`

const StepCard = styled.div`
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    overflow: hidden;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    &:hover {
        transform: translateY(-5px);
    }
`

const CityImage = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
`

const CityInfo = styled.div`
    padding: 15px;
`

const StepTitle = styled.h2`
    font-size: 1.5rem;
    color: #333;
`

const Arrow = styled.span`
    margin: 0 10px;
`

const CityNames = styled.div`
    display: flex;
    font-size: 1.2rem;
    color: #555;
`

const CityName = styled.span`
    font-weight: bold;
    color: #333;
`
