import * as React from 'react'

import styled from 'styled-components'
import { INNER_SHADOW, MAIN_COLOR, OUTER_SHADOW } from '../constant'

import { useLocation, useNavigate } from 'react-router-dom'

import Icon from '../../components/Icon'
import Drawer from 'react-motion-drawer'
import { HomeScreen } from '../screens/screens'

import useReducer from '../../utils/store/useReducer'
import * as AccountStore from '../../utils/store/account'
import * as JourneyStore from '../../utils/store/journey'

interface Props extends React.PropsWithChildren {
    items: ScreenCog[]
    current?: ScreenCog
}

const ApplicationLayout = ({ children, items, current }: Props) => {
    const { user } = useReducer(AccountStore.store, (s) => s)
    const { selected } = useReducer(JourneyStore.store, (s) => s)

    const [menu, setMenu] = React.useState<boolean>(false)

    const [width, setWidth] = React.useState<number>(window.innerWidth)
    const [height, setHeight] = React.useState<number>(window.innerHeight)

    const windowResize = () => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
    }

    React.useEffect(() => {
        window.addEventListener('resize', windowResize)
        return () => {
            window.removeEventListener('resize', windowResize)
        }
    }, [])

    const navigate = useNavigate()
    const location = useLocation()
    const backpath = current?.backpath

    return (
        <Container height={height} width={width}>
            <Header>
                <Menu onClick={() => (backpath ? navigate(backpath) : setMenu(!menu))}>
                    {backpath ? (
                        <Icon color="#000" name="back" size={30} />
                    ) : (
                        <Icon color="#000" name="menu" size={30} />
                    )}
                </Menu>
                <User src={user?.picture} onClick={() => navigate('/account')} />
            </Header>
            <Content height={height}>
                <>
                    {children}
                    {current?.action && (
                        <ActionIconContainer>
                            <IconContainer
                                onClick={() =>
                                    current.action?.destination &&
                                    navigate(current.action.destination)
                                }>
                                <Icon name={current.action.icon} color="#FFF" />
                            </IconContainer>
                        </ActionIconContainer>
                    )}
                </>
            </Content>
            <Navbar>
                <NavItem
                    key={HomeScreen.name}
                    onClick={() => navigate(HomeScreen.path)}
                    selected={location.pathname === HomeScreen.path}>
                    <Icon
                        name="home"
                        size={30}
                        color={location.pathname === HomeScreen.path ? MAIN_COLOR : '#fff'}
                    />
                </NavItem>
                {items
                    .filter((i) => !!i.iconMobile)
                    .map((itm) => (
                        <NavItem
                            key={itm.name}
                            onClick={() => navigate(itm.path)}
                            selected={location.pathname.startsWith(itm.path)}>
                            <Icon
                                name={itm.iconMobile}
                                size={30}
                                color={location.pathname.startsWith(itm.path) ? MAIN_COLOR : '#fff'}
                            />
                        </NavItem>
                    ))}
            </Navbar>
            <Drawer
                open={menu}
                onChange={setMenu}
                drawerStyle={{
                    backgroundColor: MAIN_COLOR,
                    boxShadow: '1px 1px 5px #7777',
                }}
                width={width - 50}
                noTouchOpen>
                <DrawerItems height={height}>
                    <DrawerTitle>{selected?.title}</DrawerTitle>
                    <Separator />
                    <DrawerItem
                        key={HomeScreen.name}
                        onClick={() => {
                            setMenu(!menu)
                            navigate(HomeScreen.path)
                        }}
                        selected={location.pathname === HomeScreen.path}>
                        <Icon
                            name="home"
                            color={location.pathname === HomeScreen.path ? MAIN_COLOR : '#fff'}
                        />
                        <DrawerItemTitle>{HomeScreen.title}</DrawerItemTitle>
                    </DrawerItem>
                    {items
                        .filter((i) => !!i.iconWeb)
                        .map((itm) => (
                            <DrawerItem
                                key={itm.name}
                                onClick={() => {
                                    setMenu(!menu)
                                    navigate(itm.path)
                                }}
                                selected={location.pathname.startsWith(itm.path)}>
                                <Icon
                                    name={itm.iconWeb}
                                    color={
                                        location.pathname.startsWith(itm.path) ? MAIN_COLOR : '#fff'
                                    }
                                />
                                <DrawerItemTitle>{itm.title}</DrawerItemTitle>
                            </DrawerItem>
                        ))}
                    <DrawerItem
                        key="snake"
                        onClick={() => {
                            setMenu(!menu)
                            navigate('/snake')
                        }}
                        selected={location.pathname.startsWith('/snake')}>
                        <Icon
                            name="snake"
                            color={location.pathname.startsWith('/snake') ? MAIN_COLOR : '#fff'}
                        />
                        <DrawerItemTitle>Snake</DrawerItemTitle>
                    </DrawerItem>
                </DrawerItems>
            </Drawer>
        </Container>
    )
}

export default ApplicationLayout

const Container = styled.div<{ height: number; width: number }>`
    overflow: scroll;
    height: ${(props) => props.height}px;
    width: ${(props) => props.width}px;
`
const HEADER_SIZE = 75
const HEADER_MENU = 50
const HEADER_USER = 50
const HEADER_PADDING = 10

const MENU_SIZE = 75
const MENU_MARGIN = 10
const MENU_RADIUS = 10
const ITEM_RATIO = 0.7

const CONTENT_PADDING = 10
const Content = styled.div<{ height: number }>`
    margin-bottom: 25px;
    padding: ${CONTENT_PADDING}px;
    min-height: ${(props) =>
        props.height - MENU_SIZE - 2 * MENU_MARGIN - HEADER_SIZE - 2 * HEADER_PADDING}px;
    background-color: #f5f5f5;
`
const Header = styled.div`
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 ${HEADER_PADDING}px;
    height: ${HEADER_SIZE}px;
    ${OUTER_SHADOW}
`
const Menu = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    height: ${HEADER_MENU}px;
    width: ${HEADER_MENU}px;
`
const User = styled.img`
    object-fit: cover;
    height: ${HEADER_USER}px;
    width: ${HEADER_USER}px;
    border-radius: ${HEADER_USER}px;
    ${OUTER_SHADOW}
`

const Navbar = styled.div`
    display: flex;
    z-index: 1000;
    align-items: center;
    justify-content: space-between;

    height: ${MENU_SIZE}px;
    border-radius: ${MENU_RADIUS}px;
    background-color: ${MAIN_COLOR};

    position: sticky;
    bottom: ${2 * MENU_MARGIN}px;
    margin-left: ${MENU_MARGIN}px;
    margin-right: ${MENU_MARGIN}px;

    ${OUTER_SHADOW}
    padding: 0 ${MENU_MARGIN}px;
`
const NavItem = styled.div<{ selected?: boolean }>`
    cursor: pointer;
    width: ${MENU_SIZE * ITEM_RATIO}px;
    height: ${MENU_SIZE * ITEM_RATIO}px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${MENU_RADIUS}px;

    ${(props) => props.selected && OUTER_SHADOW}
    ${(props) => props.selected && 'background-color: #fff;'}
`
const ITEM_SIZE = 50
const DRAWER_MARGIN = 10
const DRAWER_PADDING = 10
const DrawerTitle = styled.h1`
    color: #fff;
    font-size: 40px;
    margin: ${DRAWER_MARGIN}px;
`
const Separator = styled.div`
    height: 3px;
    width: 100px;
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: ${2 * DRAWER_MARGIN}px;
`
const DrawerItems = styled.div<{ height: number }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    padding: ${DRAWER_PADDING}px;
    height: ${(props) => props.height}px;
`
const DrawerItem = styled.div<{ selected?: boolean }>`
    cursor: pointer;
    height: ${ITEM_SIZE}px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: ${MENU_RADIUS}px;

    color: #fff;
    margin: ${DRAWER_MARGIN}px 0;
    padding: 0 ${DRAWER_PADDING}px;

    ${(props) => props.selected && INNER_SHADOW}
    ${(props) => props.selected && 'background-color: #fff;'}
    ${(props) => props.selected && `color: ${MAIN_COLOR}; `}
`
const DrawerItemTitle = styled.h4`
    margin-left: ${2 * DRAWER_MARGIN}px;
`
const ICON_SIZE = 40
const ActionIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`
const IconContainer = styled.div`
    height: ${ICON_SIZE}px;
    width: ${ICON_SIZE}px;
    border-radius: ${ICON_SIZE}px;
    background-color: ${MAIN_COLOR};

    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 25px;
    cursor: pointer;
    ${OUTER_SHADOW}
`
