import * as React from 'react'
import useApi from '../utils/webservices/useApi'
import { useParams } from 'react-router-dom'
import Loading from '../components/Loading'
import styled from 'styled-components'
import NoItem from '../components/NoItem'

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const Content = () => {
    const params = useParams()
    const webservices = useApi()

    const [status, setStatus] = React.useState<ScreenStatus>('loading')
    const [content, setContent] = React.useState<ContentObject | undefined>()

    React.useEffect(() => {
        if (params.contentId) {
            webservices.contents
                .get(params.contentId)
                .then((c) => {
                    setContent(c)
                    setStatus('fetched')
                })
                .catch(() => setStatus('error'))
        } else {
            setStatus('error')
        }
    }, [])

    if (status === 'loading') {
        return <Loading type="transparent" />
    }

    if (!content) {
        return <NoItem />
    }

    return (
        <Container>
            <ContentTitle>{content.title}</ContentTitle>
            <Zoom>
                <ContentImage
                    src={content.picture || '/login_background.jpeg'}
                    alt={content.title}
                />
            </Zoom>
            <ContentDescription dangerouslySetInnerHTML={{ __html: content.description }} />
        </Container>
    )
}

export default Content

const Container = styled.div`
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
        padding: 15px;
    }
`

const ContentTitle = styled.h1`
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
`

const ContentImage = styled.img`
    width: 100%;
    max-width: 800px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
`

const ContentDescription = styled.p`
    font-size: 1.2rem;
    color: #555;
    text-align: justify;
`
