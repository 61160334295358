import * as React from 'react'
import styled from 'styled-components'
import NoItem from '../NoItem'

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

interface Props {
    contents: ContentObject[]
}

const StepInfo = ({ contents }: Props) => {
    return (
        <Container>
            <ContentList>
                {contents.length === 0 ? (
                    <NoItem />
                ) : (
                    contents.map((content) => (
                        <ContentCard key={content.id}>
                            <Image src={content.picture} alt={content.title} />
                            <ContentInfo>
                                <ContentTitle>{content.title}</ContentTitle>
                                <ContentDescription
                                    dangerouslySetInnerHTML={{ __html: content.description }}
                                />
                                <Zoom>
                                    <FullImage src={content.picture} alt={content.title} />
                                </Zoom>
                            </ContentInfo>
                        </ContentCard>
                    ))
                )}
            </ContentList>
        </Container>
    )
}

export default StepInfo

const Container = styled.div`
    padding: 20px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
        padding: 15px;
    }
`
const ContentList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1200px;
`

const ContentCard = styled.div`
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
`

const Image = styled.img`
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: center top;
    filter: blur(4px);
`
const FullImage = styled.img`
    width: 100%;
    height: 300px;
    object-fit: contain;
`

const ContentInfo = styled.div`
    padding: 15px;
    text-align: left;
`

const ContentTitle = styled.h2`
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
`

const ContentDescription = styled.p`
    font-size: 1rem;
    color: #555;
`
