import React from 'react'
import styled from 'styled-components'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    BarChart,
    Bar,
    Legend,
} from 'recharts'
import { OUTER_SHADOW } from '../utils/constant'

// Composant pour afficher la progression des temps du joueur
const ProgressionChart = ({ playerId, rankings }: { playerId: string; rankings: Ranking[] }) => {
    const data = rankings.map((ranking, index) => ({
        step: `Étape ${index + 1}`,
        Temps: ranking.time,
    }))

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data} key={playerId}>
                <XAxis dataKey="step" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="Temps" stroke="#8884d8" />
            </LineChart>
        </ResponsiveContainer>
    )
}

// Comparaison des joueurs avec un graphique en barres
const ComparisonChart = ({ stepRankings }: { stepRankings: StepRanking[] }) => {
    // Exemple d'extraction des données de comparaison
    const data = stepRankings.map((step, idx) => {
        return {
            name: `Étape ${idx + 1}`,
            ...step.rankings.reduce(
                (acc, r) => {
                    acc[r.user.pseudo] = r.time
                    return acc
                },
                {} as Record<string, number>
            ),
        }
    })

    const allPlayers = Object.keys(data[0]).filter((key) => key !== 'name') // Tous les joueurs

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data}>
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {allPlayers.map((player) => (
                    <Bar key={player} dataKey={player} fill={getRandomColor()} />
                ))}
            </BarChart>
        </ResponsiveContainer>
    )
}

// Fonction pour générer des couleurs aléatoires pour chaque joueur
const getRandomColor = () => {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
    }
    return color
}

// Composant principal de la page de statistiques
const StatsComponent = ({
    playerId,
    stepRankings,
}: {
    playerId: string
    stepRankings: StepRanking[]
}) => {
    const playerRankings = stepRankings.flatMap((sr) =>
        sr.rankings.filter((r) => r.user.id === playerId)
    )

    return (
        <StatsComponentContainer>
            {/* Progression du joueur */}
            <StatsSection>
                <h3>Progression du temps par étape</h3>
                <GraphContainer>
                    <ProgressionChart rankings={playerRankings} playerId={playerId} />
                </GraphContainer>
            </StatsSection>

            {/* Comparaison avec les autres joueurs */}
            <StatsSection>
                <h3>Comparaison des performances des joueurs</h3>
                <GraphContainer>
                    <ComparisonChart stepRankings={stepRankings} />
                </GraphContainer>
            </StatsSection>
        </StatsComponentContainer>
    )
}

export default StatsComponent

// Conteneur principal
const StatsComponentContainer = styled.div`
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    background-color: #f4f4f9;
`

// Header du joueur
const PlayerHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-right: 15px;
        object-fit: cover;
        ${OUTER_SHADOW}
    }
`

// Section des statistiques
const StatsSection = styled.div`
    margin: 20px 0;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    overflow: hidden;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    &:hover {
        transform: translateY(-5px);
    }
`

// Conteneur pour les graphiques
const GraphContainer = styled.div`
    height: 300px;
    margin-bottom: 40px;
`
