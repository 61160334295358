import * as React from 'react'

import styled from 'styled-components'

import Icon from '../components/Icon'
import Input from '../components/Input'
import Select from '../components/Select'
import Button from '../components/Button'
import Loading from '../components/Loading'
import ErrorLogger from '../components/ErrorLogger'
import PictureInput from '../components/PictureInput'
import { CMPNT_HEIGHT, MAIN_COLOR, OUTER_SHADOW, SECONDARY_COLOR } from '../utils/constant'

import useApi from '../utils/webservices/useApi'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { useAlert } from 'react-alert'
import { object, string } from 'yup'
import { Formik } from 'formik'

import { logout } from '../utils/store/store'
import useReducer from '../utils/store/useReducer'
import * as AccountStore from '../utils/store/account'
import * as JourneyStore from '../utils/store/journey'

const userSchema = object({
    journeyId: string().required('Champ obligatoire'),
    pseudo: string().required('Champ obligatoire'),
    firstName: string().required('Champ obligatoire'),
    lastName: string().required('Champ obligatoire'),
    email: string().email().required('Champ obligatoire'),
    picture: string().required('Champ obligatoire'),
    sex: string().required('Champ obligatoire'),
})

interface Props {}

const Account = ({}: Props) => {
    const webservices = useApi()
    const { user, me } = useReducer(AccountStore.store, (s) => s)
    const { journeys, selected } = useReducer(JourneyStore.store, (s) => s)

    const [editing, setEditing] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const navigate = useNavigate()
    const Alert = useAlert()

    if (!user || !me || !journeys || !selected) {
        return <Loading type="transparent" />
    }

    return (
        <Container>
            <Formik
                initialValues={
                    {
                        journeyId: selected.id,
                        pseudo: user.pseudo,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email || me.email,
                        picture: user.picture,
                        sex: user.sex,
                        admin: user.admin,
                    } as CreateUserRequest
                }
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true)
                    webservices.users
                        .update(me.id, values)
                        .then(AccountStore.actions.setUser)
                        .then(() => {
                            Alert.success("L'utilisateur a bien été modifié !")
                        })
                        .catch(() => Alert.error('Un problème est survenu..'))
                        .finally(() => setSubmitting(false))
                }}
                validationSchema={userSchema}
                validateOnMount>
                {({
                    values,
                    isSubmitting,
                    handleSubmit,
                    setFieldValue,
                    errors,
                    isValid,
                    dirty,
                }) => (
                    <Content>
                        <HeaderContainer>
                            <UserPicture src={values.picture} admin={values.admin} />
                        </HeaderContainer>
                        <EditContainer onClick={() => setEditing(!editing)} editing={editing}>
                            <Icon name="pen" size={20} color={editing ? '#FFF' : MAIN_COLOR} />
                        </EditContainer>
                        <Label>Mes informations :</Label>
                        <Field>
                            <FieldTitle>Prénom : </FieldTitle>
                            <FieldValue>{values.firstName}</FieldValue>
                        </Field>
                        <Field>
                            <FieldTitle>Nom : </FieldTitle>
                            <FieldValue>{values.lastName}</FieldValue>
                        </Field>
                        <Field>
                            <FieldTitle>Email : </FieldTitle>
                            <FieldValue>{values.email}</FieldValue>
                        </Field>
                        {editing ? (
                            <>
                                <Separator />
                                <Input
                                    label="Pseudo"
                                    name="pseudo"
                                    type="pseudo"
                                    value={values.pseudo}
                                    onChange={(e) => setFieldValue('pseudo', e.currentTarget.value)}
                                    placeholder="Pseudo"
                                    error={errors.pseudo}
                                />
                            </>
                        ) : (
                            <Field>
                                <FieldTitle>Pseudo : </FieldTitle>
                                <FieldValue>{values.pseudo}</FieldValue>
                            </Field>
                        )}
                        <FlexContainer>
                            {editing && (
                                <Column>
                                    <PictureInput
                                        label="Photo de profil"
                                        value={values.picture}
                                        setValue={(v) => {
                                            setFieldValue('picture', v)
                                        }}
                                        error={errors.picture}
                                    />
                                </Column>
                            )}
                            <Column>
                                {editing && (
                                    <ButtonContainer>
                                        <Button
                                            label="Sauvegarder"
                                            onclick={handleSubmit}
                                            type="primary"
                                            position="RIGHT"
                                            status={
                                                isValid && dirty
                                                    ? isSubmitting
                                                        ? 'loading'
                                                        : 'active'
                                                    : 'disabled'
                                            }
                                        />
                                        <Button
                                            label="Déconnexion"
                                            onclick={() => {
                                                setLoading(true)
                                                setTimeout(() => {
                                                    logout()
                                                    navigate('/')
                                                }, 500)
                                            }}
                                            type="secondary"
                                            position="RIGHT"
                                            status={loading ? 'loading' : 'active'}
                                        />
                                    </ButtonContainer>
                                )}
                            </Column>
                        </FlexContainer>
                    </Content>
                )}
            </Formik>
            {journeys.length > 1 && (
                <>
                    <Separator />
                    <Label>Changer de voyage :</Label>
                    <Select
                        options={journeys.map((j) => ({ key: j.id, label: j.title }))}
                        value={selected.id}
                        setValue={(v) => {
                            const newJourney = journeys.find((j) => j.id === v)
                            if (newJourney) {
                                JourneyStore.actions.setSelected(newJourney)
                                webservices.users
                                    .meOnJourney(newJourney.id)
                                    .then(AccountStore.actions.setUser)
                                    .catch(ErrorLogger)
                                    .finally(() => navigate('/'))
                            }
                        }}
                    />
                </>
            )}
            {!editing && (
                <ButtonContainer>
                    <Button
                        label="Déconnexion"
                        onclick={() => {
                            setLoading(true)
                            setTimeout(() => {
                                logout()
                                navigate('/')
                            }, 500)
                        }}
                        type="secondary"
                        position="RIGHT"
                        status={loading ? 'loading' : 'active'}
                    />
                </ButtonContainer>
            )}
        </Container>
    )
}

export default Account

const Container = styled.div`
    margin: 0;
    padding: 0;
`
const Content = styled.div`
    margin: 0;
    padding: 10px;
`
const Field = styled.div`
    display: flex;
    align-items: center;

    margin: 0;
    padding: 0;
    margin-bottom: 15px;
`
const FieldTitle = styled.h4`
    margin: 0;
    padding: 0;
    width: 150px;
    font-size: 20px;
    font-weight: 900;
`
const FieldValue = styled.p`
    flex: 1;
    display: flex;
    align-items: center;

    margin: 0;
    ${OUTER_SHADOW}
    padding: 0 15px;
    border-radius: 10px;
    background-color: ${MAIN_COLOR};
    color: #fff;
    height: ${CMPNT_HEIGHT}px;
`
const Separator = styled.div`
    height: 3px;
    margin-top: 30px;
    margin-left: auto;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: ${MAIN_COLOR};
`
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const FlexContainer = styled.div`
    display: flex;
    ${isMobile ? 'flex-direction: column;' : 'align-items: flex-end; justify-content: flex-end;'}
`
const Column = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`
const EditContainer = styled.div<{ editing: boolean }>`
    height: 50px;
    width: 50px;
    border-radius: 100%;
    ${OUTER_SHADOW}
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${(props) => (props.editing ? SECONDARY_COLOR : '#FFF')};

    &:hover {
        & > svg {
            fill: ${(props) => (props.editing ? MAIN_COLOR : SECONDARY_COLOR)};
        }
    }

    position: relative;
    margin: auto;
    top: -45px;
    left: 60px;
`
const Label = styled.h1`
    font-size: 1.7rem;
`
const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content;
`
const PICTURE_SIZE = 150
const UserPicture = styled.img<{ admin: boolean }>`
    width: ${PICTURE_SIZE}px;
    height: ${PICTURE_SIZE}px;
    border-radius: 100%;
    ${OUTER_SHADOW}
    object-fit: cover;
    position: relative;
    margin: auto;
    border: 5px solid ${SECONDARY_COLOR};
`
