import * as React from 'react'

import styled from 'styled-components'
import { formatTime, OUTER_SHADOW } from '../utils/constant'

interface Props {
    globalRanking: GlobalRanking[]
}
const Podium = ({ globalRanking }: Props) => {
    const first = globalRanking[0]
    const second = globalRanking[1]
    const third = globalRanking[2]
    const others = globalRanking.slice(3)

    return (
        <RankingContainer>
            <PodiumContainer>
                <PodiumPlace key={second.user.firstName} rank={1}>
                    <PodiumImage
                        src={second.user.picture}
                        alt={`${second.user.firstName}'s avatar`}
                    />
                    <PodiumName>{second.user.firstName}</PodiumName>
                    <PodiumTime>{formatTime(second.time)}</PodiumTime>
                </PodiumPlace>
                <PodiumPlace key={first.user.firstName} rank={0}>
                    <PodiumImage
                        src={first.user.picture}
                        alt={`${first.user.firstName}'s avatar`}
                    />
                    <PodiumName>{first.user.firstName}</PodiumName>
                    <PodiumTime>{formatTime(first.time)}</PodiumTime>
                </PodiumPlace>
                <PodiumPlace key={third.user.firstName} rank={2}>
                    <PodiumImage
                        src={third.user.picture}
                        alt={`${third.user.firstName}'s avatar`}
                    />
                    <PodiumName>{third.user.firstName}</PodiumName>
                    <PodiumTime>{formatTime(third.time)}</PodiumTime>
                </PodiumPlace>
            </PodiumContainer>
            <RankingList>
                {others.map((rank, index) => (
                    <ItemLine key={rank.user.firstName}>
                        <ItemColumn style={{ display: 'flex', alignItems: 'center' }}>
                            <ItemPicture
                                src={rank.user.picture}
                                alt={`${rank.user.firstName}'s avatar`}
                            />
                            <ItemRank>{index + 4} - </ItemRank>
                            <ItemName>{rank.user.firstName}</ItemName>
                        </ItemColumn>
                        <ItemPoint>{formatTime(rank.time)}</ItemPoint>
                    </ItemLine>
                ))}
            </RankingList>
        </RankingContainer>
    )
}

export default Podium

const PODIUM_PICTURE_SIZE = 60
const LIST_PICTURE_SIZE = 40
const RankingContainer = styled.div``
const PodiumContainer = styled.div`
    gap: 20px;
    width: 100%;
    display: grid;
    align-items: flex-end;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(100px, 100px));
`
const PodiumPlace = styled.div<{ rank: number }>`
    ${OUTER_SHADOW}
    margin: 5px;
    padding: 5px;
    border-radius: 15px;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    background-color: ${(props) =>
        props.rank === 0 ? '#c9b037' : props.rank === 1 ? '#b4b4b4' : '#ad8a56'};
    ${(props) =>
        props.rank === 0
            ? 'height: 160px;'
            : props.rank === 1
              ? 'height: 135px;'
              : 'height: 110px;'}
`
const PodiumImage = styled.img`
    height: ${PODIUM_PICTURE_SIZE}px;
    width: ${PODIUM_PICTURE_SIZE}px;
    border-radius: 100%;
    object-fit: cover;
    ${OUTER_SHADOW}
    background-color: #FFF;
    border: 2px solid #fff;
    margin-bottom: auto;
`
const PodiumName = styled.div`
    word-break: break-word;
    font-weight: 900;
    font-size: 18px;
    color: #fff;
    margin: 0;
    padding: 0;
`
const PodiumTime = styled.div`
    font-size: 16px;
    color: #fff;
    margin: 0;
    padding: 0;
`
const RankingList = styled.div``
const ItemLine = styled.div`
    border-radius: 15px;
    background-color: #fff;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;

    padding: 10px;
    ${OUTER_SHADOW}

    display: flex;
    align-items: center;
    justify-content: space-between;
`
const ItemColumn = styled.div``
const ItemPicture = styled.img`
    height: ${LIST_PICTURE_SIZE}px;
    width: ${LIST_PICTURE_SIZE}px;
    border-radius: 100%;
    object-fit: cover;
    ${OUTER_SHADOW}
    background-color: #FFF;
    border: 2px solid #fff;
`
const ItemRank = styled.div`
    font-weight: 900;
    font-size: 18px;
    color: #000;
    margin: 10px;
    padding: 0;
`
const ItemName = styled.div`
    margin: 0;
    padding: 0;
`
const ItemPoint = styled.div`
    margin: 0;
    padding: 0;
    font-weight: 900;
    font-size: 16px;
    color: #444;
`
