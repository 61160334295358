import * as React from 'react'

import styled from 'styled-components'
import { INNER_SHADOW, MAIN_COLOR, OUTER_SHADOW, SECONDARY_COLOR } from '../utils/constant'

import { useAlert } from 'react-alert'
import { useNavigate } from 'react-router-dom'
import useApi from '../utils/webservices/useApi'

import useReducer from '../utils/store/useReducer'
import * as JourneyStore from '../utils/store/journey'
import * as AccountStore from '../utils/store/account'

import Icon from '../components/Icon'
import Button from '../components/Button'
import NoItem from '../components/NoItem'
import Loading from '../components/Loading'

import { isAfter } from 'date-fns'
import { isMobile } from 'react-device-detect'
import { Formik } from 'formik'
import { object, string } from 'yup'
import Input from '../components/Input'
import PictureInput from '../components/PictureInput'
import DateInput from '../components/DateInput'
import ErrorLogger from '../components/ErrorLogger'

interface Props {}

const journeySchema = object({
    title: string().required('Champ obligatoire'),
    description: string().required('Champ obligatoire'),
    picture: string().required('Champ obligatoire'),
})

const JourneySelector = ({}: Props) => {
    const webservices = useApi()
    const Alert = useAlert()
    const navigate = useNavigate()

    const { journeys } = useReducer(JourneyStore.store, (s) => s)
    const { me } = useReducer(AccountStore.store, (s) => s)

    const [type, setType] = React.useState<'selection' | 'creation'>('selection')

    const [width, setWidth] = React.useState<number>(window.innerWidth)
    const [height, setHeight] = React.useState<number>(window.innerHeight)

    const windowResize = () => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
    }

    React.useEffect(() => {
        if (journeys?.length === 1) {
            selectJourney(journeys[0])
        }
        window.addEventListener('resize', windowResize)
        return () => {
            window.removeEventListener('resize', windowResize)
        }
    }, [])

    const selectJourney = (itm: Journey) => {
        JourneyStore.actions.setSelected(itm)
        webservices.users.meOnJourney(itm.id).then(AccountStore.actions.setUser).catch(ErrorLogger)
    }

    const newJourney = (values: CreateJourneyRequest) => {
        webservices.journeys
            .create(values)
            .then(() => {
                Alert.success('Le voyage a bien été créé !')
                navigate('/admin')
            })
            .catch(() => Alert.error('Un problème est survenu..'))
    }

    return (
        <Container height={height} width={width}>
            {type === 'selection' ? (
                !journeys ? (
                    <Loading type="transparent" />
                ) : (
                    <>
                        <JourneysTitle>Choix du voyage</JourneysTitle>
                        {journeys.length === 0 ? (
                            <NoItem />
                        ) : (
                            journeys
                                .sort((a, b) => (isAfter(a.startDate, b.startDate) ? 1 : -1))
                                .map((journey) => (
                                    <JourneyContainer
                                        key={journey.id}
                                        onClick={() => selectJourney(journey)}>
                                        <JourneyPictureContainer>
                                            <JourneyPicture src={journey.picture} />
                                        </JourneyPictureContainer>
                                        <JourneyBanner>
                                            <JourneyTitle>{journey.title}</JourneyTitle>
                                            {journey.title && <Icon name="forward" />}
                                        </JourneyBanner>
                                    </JourneyContainer>
                                ))
                        )}
                        {me?.admin && (
                            <ButtonContainer>
                                <Button
                                    label="Créer un nouveau voyage"
                                    onclick={() => setType('creation')}
                                    type="primary"
                                    position="RIGHT"
                                    status="active"
                                />
                            </ButtonContainer>
                        )}
                    </>
                )
            ) : (
                <>
                    <JourneysTitle>Nouveau voyage</JourneysTitle>
                    <Formik
                        initialValues={{} as Journey}
                        onSubmit={(values, { setSubmitting }) => {
                            webservices.journeys
                                .update(values)
                                .then(() => {
                                    Alert.success('Le voyage a bien été modifié !')
                                    navigate('/admin')
                                })
                                .catch(() => Alert.error('Un problème est survenu..'))
                                .finally(() => setSubmitting(false))
                        }}
                        validationSchema={journeySchema}
                        validateOnMount>
                        {({
                            values,
                            isSubmitting,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            errors,
                            isValid,
                        }) => (
                            <FormikContainer>
                                <Input
                                    label="Titre du voyage"
                                    name="title"
                                    type="title"
                                    value={values.title}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Titre du voyage"
                                    error={errors.title}
                                />
                                <Input
                                    label="Description du voyage"
                                    name="description"
                                    type="long-text"
                                    value={values.description}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Description du voyage"
                                    error={errors.description}
                                />
                                <PictureInput
                                    label="Photo du voyage"
                                    value={values.picture}
                                    setValue={(v) => setFieldValue('picture', v)}
                                    error={errors.picture}
                                />
                                <DateInput
                                    label="Début du voyage"
                                    value={values.startDate}
                                    onChange={(v) => setFieldValue('startDate', v)}
                                    error={errors.startDate}
                                />
                                <DateInput
                                    label="Fin du voyage"
                                    value={values.endDate}
                                    onChange={(v) => setFieldValue('endDate', v)}
                                    error={errors.endDate}
                                />
                                <ButtonContainer>
                                    <Button
                                        onclick={() => setType('selection')}
                                        status="active"
                                        label="Annuler la création"
                                        type="secondary"
                                        position="RIGHT"
                                    />
                                    <Button
                                        onclick={() => newJourney(values)}
                                        status={
                                            !isValid
                                                ? 'disabled'
                                                : isSubmitting
                                                  ? 'loading'
                                                  : 'active'
                                        }
                                        label="Créer un nouveau voyage"
                                        type="primary"
                                        position="RIGHT"
                                    />
                                </ButtonContainer>
                            </FormikContainer>
                        )}
                    </Formik>
                </>
            )}
        </Container>
    )
}

export default JourneySelector

const Container = styled.div<{ height: number; width: number }>`
    height: ${(props) => props.height}px;
    width: ${(props) => props.width}px;
    overflow: scroll;

    background-color: ${MAIN_COLOR};
`
const JourneysTitle = styled.h1`
    color: ${SECONDARY_COLOR};
    background-color: ${MAIN_COLOR};
    text-align: center;
    position: sticky;
    padding: 20px;
    z-index: 1;
    margin: 0;
    top: 0;
`
const JOURNEY_ITEM_WIDTH = isMobile ? 300 : 600
const JOURNEY_PICTURE_RADIUS = 10
const JOURNEY_PICTURE_HEIGHT = 150
const JOURNEY_BANNER_HEIGHT = 75
const JourneyContainer = styled.div`
    margin: 40px auto;
    width: ${JOURNEY_ITEM_WIDTH}px;
    background-color: #fff;
    cursor: pointer;
    ${OUTER_SHADOW}

    &:hover {
        & > div {
            & > img {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
            }
        }

        background-color: ${SECONDARY_COLOR};
        color: #fff;
        & > div {
            & > svg {
                stroke: #fff;
            }
        }
    }
    border-radius: ${JOURNEY_PICTURE_RADIUS}px;
`
const JourneyPictureContainer = styled.div`
    overflow: hidden;
    height: ${JOURNEY_PICTURE_HEIGHT}px;
    border-radius: ${JOURNEY_PICTURE_RADIUS}px ${JOURNEY_PICTURE_RADIUS}px 0 0;
`
const JourneyPicture = styled.img`
    width: 100%;
    object-fit: cover;
    // object-position: center top;

    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.1s ease-in-out;
    height: ${JOURNEY_PICTURE_HEIGHT}px;
    border-radius: ${JOURNEY_PICTURE_RADIUS}px ${JOURNEY_PICTURE_RADIUS}px 0 0;
`
const JourneyBanner = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 20px;
    height: ${JOURNEY_BANNER_HEIGHT}px;
    cursor: pointer;
    border-radius: 0 0 ${JOURNEY_PICTURE_RADIUS}px ${JOURNEY_PICTURE_RADIUS}px;
`
const JourneyTitle = styled.h3`
    text-align: center;
    font-size: 18px;
    padding: 0;
    margin: 0;
`
const ButtonContainer = styled.div`
    display: flex;
    margin: 40px auto;
    width: ${JOURNEY_ITEM_WIDTH}px;
    ${isMobile
        ? `flex-direction: column; align-items: center;
    justify-content: space-between;`
        : `align-items: center;
    justify-content: space-between;`}
`
const FormikContainer = styled.div`
    background-color: #fff;
    max-width: 700px;
    ${isMobile ? 'margin: 0;' : 'margin: 40px auto; border-radius: 10px;'};
    padding: 20px;
    ${INNER_SHADOW}
`
