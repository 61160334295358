import * as React from 'react'

import styled from 'styled-components'
import useApi from '../utils/webservices/useApi'
import Loading from '../components/Loading'
import NoItem from '../components/NoItem'
import { formatTime, OUTER_SHADOW, SECONDARY_COLOR } from '../utils/constant'
import { isAfter } from 'date-fns'

const Ranking = () => {
    const webservices = useApi()

    const [status, setStatus] = React.useState<ScreenStatus>('loading')

    const [steps, setSteps] = React.useState<Step[]>([])
    const [cities, setCities] = React.useState<City[]>([])
    const [stepRankings, setStepRankings] = React.useState<StepRanking[]>([])
    const [selectedStep, setSelectedStep] = React.useState<Step | undefined>()

    React.useEffect(() => {
        Promise.all([
            webservices.journeys.getAvailableSteps(),
            webservices.journeys.getAvailableCities(),
            webservices.journeys.getRanking(),
        ])
            .then(([s, c, r]) => {
                setSteps(
                    s.sort((a, b) => {
                        const AendCity = c.cities.find((city) => city.id === a.endCityId)
                        const BendCity = c.cities.find((city) => city.id === b.endCityId)
                        a.title.localeCompare(b.title)
                        return AendCity && BendCity
                            ? isAfter(AendCity.startDate, BendCity.startDate)
                                ? 1
                                : -1
                            : 1
                    })
                )
                setSelectedStep(s[0])
                setCities(c.cities)
                setStepRankings(r)
                setStatus('fetched')
            })
            .catch(() => setStatus('error'))
    }, [])

    const getCity = (cityId: string) => cities.find((city) => city.id === cityId)
    const getStepRanking = (stepId: string) =>
        stepRankings.find((ranking) => ranking.stepId === stepId)

    if (!selectedStep || steps.length === 0 || cities.length === 0 || stepRankings.length === 0) {
        return <NoItem />
    }

    if (status === 'loading') {
        return <Loading type="transparent" />
    }

    const stepRanking = getStepRanking(selectedStep.id)
    const startCity = getCity(selectedStep.startCityId)
    const endCity = getCity(selectedStep.endCityId)

    if (!stepRanking || !startCity || !endCity) {
        return <NoItem />
    }

    return (
        <RankingContainer>
            <StepSelector>
                {steps.map((itm, idx) => (
                    <StepItem
                        onClick={() => setSelectedStep(itm)}
                        selected={selectedStep && itm.id === selectedStep.id}>
                        {itm.title}
                    </StepItem>
                ))}
            </StepSelector>
            <Container>
                <StepSection key={selectedStep.id}>
                    <StepTitle>{selectedStep.title}</StepTitle>
                    <CityInfo>
                        <CityName>{startCity?.name}</CityName>
                        <Arrow> → </Arrow>
                        <CityName>{endCity?.name}</CityName>
                    </CityInfo>
                    <RankingTable>
                        <thead>
                            <tr>
                                <th>Rang</th>
                                <th>Joueur</th>
                                <th>Temps</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stepRanking?.rankings.map((ranking) => (
                                <tr key={ranking.user.id}>
                                    <td>{ranking.rank}</td>
                                    <td>
                                        <UserInfo>
                                            <UserPicture
                                                src={ranking.user.picture}
                                                alt={ranking.user.firstName}
                                            />
                                            <NameContainer>
                                                <UserName>{ranking.user.firstName}</UserName>
                                                <Pseudo>{ranking.user.pseudo}</Pseudo>
                                            </NameContainer>
                                        </UserInfo>
                                    </td>
                                    <td>{formatTime(ranking.time)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </RankingTable>
                </StepSection>
            </Container>
        </RankingContainer>
    )
}

export default Ranking

const RankingContainer = styled.div``
const Container = styled.div`
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const StepSection = styled.div`
    width: 100%;
    max-width: 800px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    margin-top: 20px;
    padding: 20px;
`
const StepTitle = styled.h2`
    font-size: 1.5rem;
    margin-top: 0px;
    color: #333;
`
const CityInfo = styled.div`
    display: flex;
    justify-content: flex-start;
    font-size: 1rem;
    color: #555;
    margin: 10px 0;
`
const CityName = styled.span`
    font-weight: bold;
`

const RankingTable = styled.table`
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;

    th,
    td {
        padding: 10px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    th {
        background-color: #f0f0f0;
        font-weight: bold;
    }

    tr:hover {
        background-color: #f9f9f9;
    }
`

const UserInfo = styled.div`
    display: flex;
    align-items: center;
`

const UserPicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
`
const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`
const UserName = styled.span`
    font-size: 1rem;
`
const Pseudo = styled.span`
    font-size: 0.75rem;
    color: #777;
`
const Arrow = styled.span`
    margin: 0 10px;
`
const StepSelector = styled.div`
    ${OUTER_SHADOW}
    z-index: 10000;
    display: flex;
    position: sticky;
    top: 10px;
    margin: 0 10px;
    flex-direction: row;
    border-radius: 5px;
    background-color: #eee;

    padding: 5px;
    overflow: scroll;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    justify-content: space-between;
`
const StepItem = styled.div<{ selected?: boolean }>`
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
    ${(props) =>
        props.selected &&
        `background-color: ${SECONDARY_COLOR};
    color: #fff;
    font-weight: 900;`}
    min-width: fit-content;
`
