// ADMIN
import Admin from '../../screens/Admin'
// ADMIN JOURNEY
import EditJourney from '../../components/admin/journey/Edit'
// ADMIN USERS
import AdminListUsers from '../../components/admin/users/List'
import AddEditUser from '../../components/admin/users/AddEdit'
// ADMIN CITIES
import AdminListCities from '../../components/admin/cities/List'
import AddEditCity from '../../components/admin/cities/AddEdit'
// ADMIN STEPS
import AdminListSteps from '../../components/admin/steps/List'
import AddEditStep from '../../components/admin/steps/AddEdit'
// ADMIN JOURNEY CONTENTS
import AdminListJourneyContents from '../../components/admin/journeyContents/List'
import AddEditJourneyContent from '../../components/admin/journeyContents/AddEdit'
// ADMIN STEP CONTENTS
import AdminListStepContents from '../../components/admin/stepContents/List'
import AddStepContent from '../../components/admin/stepContents/Add'
import EditStepContent from '../../components/admin/stepContents/Edit'
// ADMIN CHALLENGES
import AdminListChallenges from '../../components/admin/challenges/List'
import AddEditChallenge from '../../components/admin/challenges/AddEdit'
// ADMIN RESPONSES
import AdminListResponses from '../../components/admin/responses/ResponsesList'
import AdminListResponsesChallenges from '../../components/admin/responses/ResponseChallengeList'
import AdminListResponsesSteps from '../../components/admin/responses/ResponseStepsList'
// ADMIN BONUS
import AdminListBonus from '../../components/admin/bonus/List'
import AddEditBonus from '../../components/admin/bonus/Add'
// ADMIN TEAMS
import AdminListTeams from '../../components/admin/teams/List'

// SCREENS
import Home from '../../screens/Home'
import Step from '../../screens/Step'
import City from '../../screens/City'
import Content from '../../screens/Content'
import Challenge from '../../screens/Challenge'
import Contents from '../../screens/Contents'
import Steps from '../../screens/Steps'
import Ranking from '../../screens/Ranking'
import Stats from '../../screens/Stats'
import Account from '../../screens/Account'
import JourneySelector from '../../screens/JourneySelector'
import SnakeGame from '../../screens/Snake'

const HomeScreen: ScreenCog = {
    name: 'homepage',
    title: 'Accueil',
    path: '/',
    component: Home,
}

const AppScreens: ScreenCog[] = [
    {
        name: 'step',
        title: '',
        path: '/steps/:stepId',
        component: Step,
    },
    {
        name: 'city',
        title: '',
        path: '/cities/:cityId',
        component: City,
    },
    {
        name: 'content',
        title: '',
        path: '/contents/:contentId',
        component: Content,
    },
    {
        name: 'challenge',
        title: '',
        path: '/challenges/:challengeId',
        component: Challenge,
    },
    {
        name: 'contents',
        title: 'Contenus',
        path: '/contents',
        component: Contents,
        iconWeb: 'info',
        iconMobile: 'info',
    },
    {
        name: 'steps',
        title: 'Étapes',
        path: '/steps',
        component: Steps,
        iconWeb: 'travels',
        iconMobile: 'travels',
    },
    {
        name: 'ranking',
        title: 'Ranking',
        path: '/ranking',
        component: Ranking,
        iconWeb: 'ranking',
        iconMobile: 'ranking',
    },
    {
        name: 'stats',
        title: 'Stats',
        path: '/stats',
        component: Stats,
        iconWeb: 'stats',
        iconMobile: 'stats',
    },
    {
        name: 'account',
        title: 'Mon compte',
        path: '/account',
        component: Account,
    },
]

const AdminScreens: ScreenCog[] = [
    // ADMINISTRATION GLOBALE
    {
        name: 'admin',
        title: 'Administration',
        path: '/admin',
        component: Admin,
        iconWeb: 'admin',
    },
    // ADMINISTRATION DES VOYAGES
    {
        name: 'adminupdatejourney',
        title: 'Voyage',
        path: '/admin/journey',
        component: EditJourney,
        adminIcon: 'travel',
        backpath: '/admin',
    },
    // ADMINISTRATION DES UTILISATEURS
    {
        name: 'adminlistusers',
        title: 'Utilisateurs',
        path: '/admin/users',
        component: AdminListUsers,
        adminIcon: 'users',
        action: {
            icon: 'add',
            destination: '/admin/users/new',
        },
        backpath: '/admin',
    },
    {
        name: 'adminnewuser',
        title: 'Nouvel utilisateur',
        path: '/admin/users/new',
        component: AddEditUser,
        backpath: '/admin/users',
    },
    {
        name: 'adminupdateuser',
        title: 'Utilisateur',
        path: '/admin/users/:userid',
        component: AddEditUser,
        backpath: '/admin/users',
    },
    // ADMINISTRATION DES VILLES
    {
        name: 'adminlistcities',
        title: 'Villes',
        path: '/admin/cities',
        component: AdminListCities,
        adminIcon: 'city',
        action: {
            icon: 'add',
            destination: '/admin/cities/new',
        },
        backpath: '/admin',
    },
    {
        name: 'adminnewcity',
        title: 'Nouvelle ville',
        path: '/admin/cities/new',
        component: AddEditCity,
        backpath: '/admin/cities',
    },
    {
        name: 'adminupdatecity',
        title: 'Ville',
        path: '/admin/cities/:cityId',
        component: AddEditCity,
        backpath: '/admin/cities',
    },
    // ADMINISTRATION DES ETAPES
    {
        name: 'adminliststeps',
        title: 'Étapes',
        path: '/admin/steps',
        component: AdminListSteps,
        adminIcon: 'steps',
        action: {
            icon: 'add',
            destination: '/admin/steps/new',
        },
        backpath: '/admin',
    },
    {
        name: 'adminnewstep',
        title: 'Nouvelle étape',
        path: '/admin/steps/new',
        component: AddEditStep,
        backpath: '/admin/steps',
    },
    {
        name: 'adminupdatestep',
        title: 'Étape',
        path: '/admin/steps/:stepId',
        component: AddEditStep,
        backpath: '/admin/steps',
    },
    // ADMINISTRATION DES CONTENUS DE VOYAGE
    {
        name: 'adminlistcontents',
        title: 'Contenus du voyage',
        path: '/admin/journeyContents',
        component: AdminListJourneyContents,
        adminIcon: 'content',
        action: {
            icon: 'add',
            destination: '/admin/journeyContents/new',
        },
        backpath: '/admin',
    },
    {
        name: 'adminnewcontent',
        title: 'Nouveau contenu sur le voyage',
        path: '/admin/journeyContents/new',
        component: AddEditJourneyContent,
        backpath: '/admin/journeyContents',
    },
    {
        name: 'adminupdatecontent',
        title: 'Contenu du voyage',
        path: '/admin/journeyContents/:contentId',
        component: AddEditJourneyContent,
        backpath: '/admin/journeyContents',
    },
    // ADMINISTRATION DES CONTENUS D'ÉTAPE
    {
        name: 'adminlistcontents',
        title: "Contenus d'étape",
        path: '/admin/stepContents',
        component: AdminListStepContents,
        adminIcon: 'content',
        action: {
            icon: 'add',
            destination: '/admin/stepContents/new',
        },
        backpath: '/admin',
    },
    {
        name: 'adminnewcontent',
        title: "Nouveau contenu sur l'étape",
        path: '/admin/stepContents/new',
        component: AddStepContent,
        backpath: '/admin/stepContents',
    },
    {
        name: 'adminupdatecontent',
        title: "Contenu d'étape",
        path: '/admin/stepContents/:contentId',
        component: EditStepContent,
        backpath: '/admin/stepContents',
    },
    // ADMIN DES CHALLENGES
    {
        name: 'adminlistchallenges',
        title: 'Challenges',
        path: '/admin/challenges',
        component: AdminListChallenges,
        adminIcon: 'challenge',
        action: {
            icon: 'add',
            destination: '/admin/challenges/new',
        },
        backpath: '/admin',
    },
    {
        name: 'adminnewchallenge',
        title: 'Nouveau challenge',
        path: '/admin/challenges/new',
        component: AddEditChallenge,
        backpath: '/admin/challenges',
    },
    {
        name: 'adminupdatechallenge',
        title: 'Challenges',
        path: '/admin/challenges/:challengeId',
        component: AddEditChallenge,
        backpath: '/admin/challenges',
    },
    // ADMIN DES REPONSES
    {
        name: 'adminlistresponsessteps',
        title: 'Réponses aux challenges',
        path: '/admin/responses',
        component: AdminListResponsesSteps,
        adminIcon: 'flag',
        backpath: '/admin',
    },
    {
        name: 'adminlistresponseschallenges',
        title: 'Réponses aux challenges',
        path: '/admin/responses/:stepId',
        component: AdminListResponsesChallenges,
        backpath: '/admin/responses',
    },
    {
        name: 'adminlistresponses',
        title: 'Réponses aux challenges',
        path: '/admin/responses/:stepId/:challengeId',
        component: AdminListResponses,
        backpath: '/admin/responses',
    },
    // ADMIN DES BONUS
    {
        name: 'adminListBonus',
        title: 'Bonus',
        path: '/admin/bonus',
        component: AdminListBonus,
        adminIcon: 'ranking',
        backpath: '/admin',
    },
    {
        name: 'adminnewbonus',
        title: 'Nouveau bonus',
        path: '/admin/bonus/:stepId/new',
        component: AddEditBonus,
        backpath: '/admin/bonus',
    },
    // ADMIN DES TEAMS
    {
        name: 'adminlistteams',
        title: 'Équipes',
        path: '/admin/teams',
        component: AdminListTeams,
        adminIcon: 'users',
        backpath: '/admin',
    },
]

const JourneySelectorScreen = [
    {
        name: 'journeySelector',
        title: 'Sélection du voyage',
        path: '/',
        component: JourneySelector,
        adminIcon: 'challenge',
    },
]

export default AppScreens
export { HomeScreen, AdminScreens, JourneySelectorScreen }
