import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { OUTER_SHADOW } from '../utils/constant'
import { useNavigate } from 'react-router-dom'

// Dimensions du jeu
const BOARD_SIZE = 20

const directions = {
    ArrowUp: { x: 0, y: -1 },
    ArrowDown: { x: 0, y: 1 },
    ArrowLeft: { x: -1, y: 0 },
    ArrowRight: { x: 1, y: 0 },
}

type Direction = keyof typeof directions

const initialSnake = [{ x: 10, y: 10 }]
const initialDirection: Direction = 'ArrowRight'

const SCREEN_MARGIN = 10
const SCREEN_BORDER = 10

const SnakeGame: React.FC = () => {
    const navigate = useNavigate()

    const [snake, setSnake] = useState(initialSnake)
    const [direction, setDirection] = useState<Direction>(initialDirection)
    const [food, setFood] = useState<{ x: number; y: number }>({ x: 5, y: 5 })
    const [isGameOver, setIsGameOver] = useState(false)

    const [height, setHeight] = React.useState<number>(window.innerHeight)
    const [width, setWidth] = React.useState<number>(window.innerWidth)

    const windowResize = () => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
    }

    React.useEffect(() => {
        window.addEventListener('resize', windowResize)
        return () => {
            window.removeEventListener('resize', windowResize)
        }
    }, [])

    // Met à jour la direction
    const changeDirection = (newDirection: Direction) => {
        if (Math.abs(directions[newDirection].x) !== Math.abs(directions[direction].x)) {
            setDirection(newDirection)
        }
    }

    // Gère le mouvement du snake
    const moveSnake = () => {
        const newSnake = [...snake]
        const head = { ...newSnake[0] }
        head.x += directions[direction].x
        head.y += directions[direction].y

        // Vérification des collisions
        if (
            head.x < 0 ||
            head.y < 0 ||
            head.x >= BOARD_SIZE ||
            head.y >= BOARD_SIZE ||
            newSnake.some((part) => part.x === head.x && part.y === head.y)
        ) {
            setIsGameOver(true)
            return
        }

        newSnake.unshift(head)

        // Si le snake mange la nourriture
        if (head.x === food.x && head.y === food.y) {
            setFood({
                x: Math.floor(Math.random() * BOARD_SIZE),
                y: Math.floor(Math.random() * BOARD_SIZE),
            })
        } else {
            newSnake.pop()
        }

        setSnake(newSnake)
    }

    // Contrôle du clavier
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            const newDirection = e.key as Direction
            if (directions[newDirection]) {
                changeDirection(newDirection)
            }
        }

        window.addEventListener('keydown', handleKeyDown)
        return () => window.removeEventListener('keydown', handleKeyDown)
    }, [direction])

    // Boucle du jeu
    useEffect(() => {
        if (isGameOver) return
        const interval = setInterval(moveSnake, Math.max(200 - 5 * snake.length, 70))
        return () => clearInterval(interval)
    }, [snake, direction])

    const CELL_SIZE = (width - 2 * SCREEN_BORDER - 2 * SCREEN_MARGIN) / BOARD_SIZE

    return (
        <GameBoy height={height} width={width}>
            <Screen width={width}>
                {isGameOver ? (
                    <GameOver>Game Over</GameOver>
                ) : (
                    <Board cellSize={CELL_SIZE}>
                        {Array.from({ length: BOARD_SIZE }).map((_, row) =>
                            Array.from({ length: BOARD_SIZE }).map((_, col) => (
                                <Cell
                                    cellSize={CELL_SIZE}
                                    key={`${row}-${col}`}
                                    isSnake={snake.some((part) => part.x === col && part.y === row)}
                                    isFood={food.x === col && food.y === row}
                                />
                            ))
                        )}
                    </Board>
                )}
            </Screen>
            <OptionsContainer>
                <BackButtonContainer>
                    <BackButton onClick={() => navigate('/')}>Back</BackButton>
                </BackButtonContainer>
                <ScoreContainer>{snake.length - 1}</ScoreContainer>
                <ResetButtonContainer>
                    <ResetButton
                        onClick={() => {
                            setSnake(initialSnake)
                            setIsGameOver(false)
                        }}>
                        Reset
                    </ResetButton>
                </ResetButtonContainer>
            </OptionsContainer>
            <Controls>
                <ControlButton onClick={() => changeDirection('ArrowUp')}>↑</ControlButton>
                <DirectionRow>
                    <ControlButton onClick={() => changeDirection('ArrowLeft')}>←</ControlButton>
                    <ControlButton onClick={() => changeDirection('ArrowRight')}>→</ControlButton>
                </DirectionRow>
                <ControlButton onClick={() => changeDirection('ArrowDown')}>↓</ControlButton>
            </Controls>
        </GameBoy>
    )
}

// Composants stylisés
const GameBoy = styled.div<{ height: number; width: number }>`
    background-color: #6a0dad; // Couleur violette
    height: ${(props) => props.height}px;
    width: ${(props) => props.width}px;

    display: flex;
    flex-direction: column;
`

const Screen = styled.div<{ width: number }>`
    background-color: #c0c0c0;
    margin: ${SCREEN_MARGIN}px;
    margin-top: ${SCREEN_MARGIN * 2}px;
    height: ${(props) => props.width - 2 * SCREEN_MARGIN - 2 * SCREEN_BORDER}px;
    width: ${(props) => props.width - 2 * SCREEN_MARGIN - 2 * SCREEN_BORDER}px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: ${SCREEN_BORDER}px solid #000;
`

const Board = styled.div<{ cellSize: number }>`
    display: grid;
    border-radius: 20px;
    grid-template-columns: repeat(${BOARD_SIZE}, ${(props) => props.cellSize}px);
    grid-template-rows: repeat(${BOARD_SIZE}, ${(props) => props.cellSize}px);
`

const Cell = styled.div<{ isSnake: boolean; isFood: boolean; cellSize: number }>`
    width: ${(props) => props.cellSize}px;
    height: ${(props) => props.cellSize}px;
    background-color: ${({ isSnake, isFood }) =>
        isSnake ? 'green' : isFood ? 'red' : '#B1B423EE'};

    border: 1px solid #777;
`

const GameOver = styled.div`
    font-weight: 900;
    font-size: 40px;
    color: red;
`

const Controls = styled.div`
    display: none;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin: ${SCREEN_MARGIN}px;
        display: flex;
        flex: 1;
    }
`

const DirectionRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 200px;
    margin: 10px;
`
const CONTROL_BUTTON_SIZE = 60
const ControlButton = styled.button`
    background-color: #000;
    font-weight: 900;
    font-size: 24px;
    color: white;

    border-radius: 50%;
    width: ${CONTROL_BUTTON_SIZE}px;
    height: ${CONTROL_BUTTON_SIZE}px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    cursor: pointer;
    ${OUTER_SHADOW}

    &:active {
        background-color: #333;
    }
`

const OptionsContainer = styled.div`
    display: flex;
    margin: 0 ${2 * SCREEN_MARGIN}px;
`

const ScoreContainer = styled.div`
    flex: 1;
    padding: 3px;
    display: flex;
    font-weight: 500;
    border-radius: 5px;
    align-items: center;
    border: 4px solid #000;
    justify-content: flex-end;
    background-color: #b1b423ee;
`
const ResetButtonContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`
const ResetButton = styled.div`
    border: 3px solid #000;
    background-color: #000;
    border-radius: 100px;
    color: #eee;
    padding: 0 20px;
    ${OUTER_SHADOW}
`
const BackButtonContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`
const BackButton = styled.div`
    border: 3px solid #000;
    background-color: #000;
    border-radius: 100px;
    color: #eee;
    padding: 0 20px;
    ${OUTER_SHADOW}
`

export default SnakeGame
