import * as React from 'react'

import styled from 'styled-components'

import { isAfter } from 'date-fns'
import { useAlert } from 'react-alert'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import useApi from '../../../utils/webservices/useApi'
import { MAIN_COLOR, OUTER_SHADOW, SECONDARY_COLOR } from '../../../utils/constant'

import Modal from 'react-modal'
import Loading from '../../Loading'
import Button from '../../Button'
import Icon from '../../Icon'
import ErrorLogger from '../../ErrorLogger'

interface Props {}

interface StepTeam {
    step: Step
    teams: Team[]
}

interface ModalContent {
    stepId: string
    teamId: string
}

const EMPTY_USER = {}

const ListTeams = ({}: Props) => {
    const Alert = useAlert()
    const webservices = useApi()
    const navigate = useNavigate()

    const [status, setStatus] = React.useState<ScreenStatus>('loading')
    const [generating, setGenerating] = React.useState<boolean>(false)

    const [modal, setModal] = React.useState<ModalContent>()
    const [contestants, setContestants] = React.useState<JourneyUser[]>([])
    const [steps, setSteps] = React.useState<Step[]>([])
    const [stepTeams, setStepTeams] = React.useState<StepTeam[]>([])

    React.useEffect(() => {
        Promise.all([
            webservices.journeys.getSteps(),
            webservices.journeys.getCities(),
            webservices.journeys.getUsers(),
        ])
            .then(([s, c, u]) => {
                setContestants(u)
                setSteps(
                    s.sort((a, b) => {
                        const AendCity = c.find((city) => city.id === a.endCityId)
                        const BendCity = c.find((city) => city.id === b.endCityId)
                        a.title.localeCompare(b.title)
                        return AendCity && BendCity
                            ? isAfter(AendCity.startDate, BendCity.startDate)
                                ? 1
                                : -1
                            : -1
                    })
                )
            })
            .catch(() => setStatus('error'))
    }, [])

    const getAllTeams = (callback: () => void) => {
        setStatus('loading')
        Promise.all(
            steps.map((step) =>
                webservices.steps.getTeams(step.id).then((t) => ({ step, teams: t }) as StepTeam)
            )
        )
            .then(setStepTeams)
            .then(() => setStatus('fetched'))
            .catch(() => setStatus('error'))
            .finally(callback)
    }

    const addUser = (stepId: string, teamId: string, user: SmallUser) => {
        const newTeams = stepTeams.map((st) =>
            st.step.id === stepId
                ? {
                      step: st.step,
                      teams: st.teams.map((t) =>
                          t.id === teamId ? { ...t, users: [...t.users, user] } : t
                      ),
                  }
                : st
        )
        setStepTeams(newTeams)
    }

    const removeUser = (stepId: string, teamId: string, user: SmallUser) => {
        const newTeams = stepTeams.map((st) =>
            st.step.id === stepId
                ? {
                      step: st.step,
                      teams: st.teams.map((t) =>
                          t.id === teamId
                              ? { ...t, users: t.users.filter((u) => u.id !== user.id) }
                              : t
                      ),
                  }
                : st
        )
        setStepTeams(newTeams)
    }

    React.useEffect(() => {
        getAllTeams(() => {})
    }, [steps])

    const renderItem = (item: Team, stepId: string) => (
        <TeamCard key={item.id}>
            <UserList>
                {item.users.map((user) => (
                    <UserCard key={user.id}>
                        <UserPicture
                            src={user.picture}
                            alt={`${user.firstName} ${user.lastName}`}
                        />
                        <UserInfos className="user-info">
                            <UserName className="user-name">{user.firstName}</UserName>
                            <UserPseudo className="user-pseudo">{user.firstName}</UserPseudo>
                        </UserInfos>
                        <IconContainer
                            onClick={() => {
                                webservices.teams
                                    .associateUser(item.id, user.id)
                                    .then(() => {
                                        removeUser(stepId, item.id, user)
                                    })
                                    .catch(() => Alert.error('Un problème est survenu..'))
                            }}>
                            <Icon name="unassociate" />
                        </IconContainer>
                    </UserCard>
                ))}
                {item.users.length <= 2 &&
                    new Array(2 - item.users.length).fill('').map((user, idx) => (
                        <UserCard key={idx}>
                            <UserInfos className="user-info">
                                <UserName className="user-name"></UserName>
                                <UserPseudo className="user-pseudo"></UserPseudo>
                            </UserInfos>
                            <IconContainer
                                onClick={() =>
                                    setModal({
                                        stepId: item.stepId,
                                        teamId: item.id,
                                    })
                                }>
                                <Icon name="associate" />
                            </IconContainer>
                        </UserCard>
                    ))}
            </UserList>
        </TeamCard>
    )

    if (status !== 'fetched' || steps.length === 0 || stepTeams.length === 0) {
        return <Loading type="transparent" />
    }

    return (
        <Container>
            <Modal isOpen={!!modal} style={{}} contentLabel="Example Modal">
                <UsersChoiceContainer>
                    {contestants.map((user, idx) => (
                        <UserCard
                            key={idx}
                            onClick={() => {
                                if (modal) {
                                    webservices.teams
                                        .unassociateUser(modal.teamId, user.id)
                                        .then(() => {
                                            addUser(modal.stepId, modal.teamId, user)
                                            setModal(undefined)
                                        })
                                        .catch(() => Alert.error('Un problème est survenu..'))
                                }
                            }}>
                            <UserPicture
                                src={user.picture}
                                alt={`${user.firstName} ${user.lastName}`}
                            />
                            <UserInfos className="user-info">
                                <UserName className="user-name">{user.firstName}</UserName>
                                <UserPseudo className="user-pseudo">{user.firstName}</UserPseudo>
                            </UserInfos>
                        </UserCard>
                    ))}
                </UsersChoiceContainer>
            </Modal>
            {stepTeams.map((st, idx) => {
                return (
                    <StepContent key={idx}>
                        <StepTitle>{st.step.title}</StepTitle>
                        {st.teams.length === 0 ? (
                            <NoContent>Aucun équipe disponible</NoContent>
                        ) : (
                            st.teams.map((s) => renderItem(s, st.step.id))
                        )}
                        <ButtonContainer>
                            <Button
                                position="RIGHT"
                                status="active"
                                type="primary"
                                label="Nouvelle équipe"
                                onclick={() => {
                                    webservices.steps
                                        .createTeam(st.step.id, { name: '' })
                                        .then(() => {
                                            setSteps([...steps])
                                        })
                                        .catch(ErrorLogger)
                                }}
                            />
                        </ButtonContainer>
                    </StepContent>
                )
            })}
            <Warning>
                Attention, la regénaration des équipes va réinitialiser l'ensemble des réponses,
                arrivées et classement du voyage. Si vous êtes sur de vous alors double cliquez sur
                le bouton. Bisous.
            </Warning>
            <ButtonContainer>
                <Button
                    label="Génération des équipes"
                    type="primary"
                    status={generating ? 'loading' : 'active'}
                    position="RIGHT"
                    double
                    onclick={() => {
                        setGenerating(true)
                        webservices.journeys
                            .generateTeams()
                            .then(() => {
                                Alert.success('Les équipes ont bien été générées !')
                                getAllTeams(() => {
                                    setGenerating(false)
                                })
                            })
                            .catch(() => Alert.error('Un problème est survenu..'))
                    }}
                />
            </ButtonContainer>
        </Container>
    )
}

export default ListTeams

const Container = styled.div`
    margin: 10px;
`
const StepContent = styled.div``
const StepTitle = styled.h2`
    font-size: 25px;
    font-weight: 700;
    color: #eee;

    margin: 0;
    padding: 10px 30px;
    border-radius: 5px;
    background-color: ${MAIN_COLOR};
    ${OUTER_SHADOW}
`
const NoContent = styled.div`
    margin-bottom: 20px;
    padding: 20px 40px;
`
const ButtonContainer = styled.div`
    display: flex;
`
const Warning = styled.p`
    color: #f00;
    text-align: right;
    margin: 30px 0;
`
const TeamCard = styled.div`
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border-radius: 15px;
    color: #333;

    transition:
        transform 0.3s ease,
        box-shadow 0.3s ease;
    cursor: pointer;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
    }

    flex-direction: column;
    margin: 10px 0;
    display: flex;
    width: 100%;
`

const UserList = styled.div`
    display: flex;
    justify-content: center;
    ${isMobile
        ? `
        flex-direction: column;
        align-items: center;
        justify
        `
        : 'flex-direction: row;'}
`
const UserCard = styled.div`
    width: 100%;
    background-color: #f0f4f8;
    color: #333;

    border-radius: 15px;
    transition:
        background-color 0.2s ease,
        transform 0.2s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    &:hover {
        background-color: #e0e7ef;
        transform: scale(1.1);
    }

    img {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.2s ease;

        &:hover {
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
        }
    }

    flex: 1;
    margin: 10px;
    padding: 10px;
    display: flex;
    cursor: pointer;
    align-items: center;
`
const PICTURE_SIZE = 50
const UserPicture = styled.img`
    height: ${PICTURE_SIZE}px;
    width: ${PICTURE_SIZE}px;
    border-radius: 100%;
    object-fit: cover;
    ${OUTER_SHADOW}
`
const UserInfos = styled.div`
    flex: 1;
    display: flex;
    margin-left: 10px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`
const UserName = styled.div`
    font-weight: 900;
    font-size: 18px;
    color: #000;
`
const UserPseudo = styled.div`
    font-weight: 500;
    font-size: 16px;
    color: #555;
`
const IconContainer = styled.div`
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        & > svg {
            fill: ${SECONDARY_COLOR};
        }
    }
`
const UsersChoiceContainer = styled.div`
    padding: 20px;
`
