import * as React from 'react'
import styled from 'styled-components'
import NoItem from '../NoItem'
import { formatTime } from '../../utils/constant'

interface Props {
    rankings: Ranking[]
}

const StepRanking = ({ rankings }: Props) => {
    return (
        <Container>
            <RankingTable>
                <TableHeader>
                    <HeaderCell>#</HeaderCell>
                    <HeaderCell>Joueur</HeaderCell>
                    <HeaderCell>Temps</HeaderCell>
                </TableHeader>
                <TableBody>
                    {rankings.length === 0 ? (
                        <NoItem />
                    ) : (
                        rankings.map((ranking) => (
                            <TableRow key={ranking.user.id}>
                                <TableCell>{ranking.rank}</TableCell>
                                <TableCell>
                                    <UserInfo>
                                        <Avatar
                                            src={ranking.user.picture}
                                            alt={ranking.user.firstName}
                                        />
                                        <NameContainer>
                                            <UserName>{ranking.user.firstName}</UserName>
                                            <Pseudo>{ranking.user.pseudo}</Pseudo>
                                        </NameContainer>
                                    </UserInfo>
                                </TableCell>
                                <TableCell>{formatTime(ranking.time)}</TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </RankingTable>
        </Container>
    )
}

export default StepRanking

const Container = styled.div`
    padding: 20px;
    background-color: #f5f5f5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
        padding: 15px;
    }
`

const RankingTable = styled.div`
    width: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
`

const TableHeader = styled.div`
    display: grid;
    grid-template-columns: 50px 1fr 100px;
    background-color: #333;
    color: white;
    padding: 10px;
`

const TableBody = styled.div`
    display: grid;
    gap: 1px;
    background-color: #ddd;
`

const TableRow = styled.div`
    display: grid;
    grid-template-columns: 50px 1fr 100px;
    background-color: white;
    padding: 10px;
    align-items: center;
`

const HeaderCell = styled.div`
    font-weight: bold;
    text-align: center;
`

const TableCell = styled.div`
    text-align: center;
`

const UserInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

const Avatar = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
`
const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`
const UserName = styled.span`
    font-size: 1rem;
`
const Pseudo = styled.span`
    font-size: 0.75rem;
    color: #777;
`
