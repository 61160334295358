import * as React from 'react'

import {
    createRoutesFromElements,
    createBrowserRouter,
    RouterProvider,
    Route,
} from 'react-router-dom'

import AppScreens, {
    AdminScreens,
    HomeScreen,
    JourneySelectorScreen,
} from './utils/screens/screens'

import ApplicationLayout from './utils/layout/ApplicationLayout'
import NotFound from './screens/NotFound'
import Login from './screens/Login'

import useApi from './utils/webservices/useApi'
import useReducer from './utils/store/useReducer'
import * as AccountStore from './utils/store/account'
import * as JourneyStore from './utils/store/journey'
import ErrorLogger from './components/ErrorLogger'
import SnakeGame from './screens/Snake'

const loginRouter = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            <Route key="login" path="/" element={<Login />} errorElement={<NotFound />} />
            <Route key={'snake'} path={'/snake'} element={<SnakeGame />} />
        </Route>
    )
)
const AllScreens = (journeyAdmin: boolean) =>
    journeyAdmin ? [HomeScreen, ...AppScreens, ...AdminScreens] : [HomeScreen, ...AppScreens]
const authRouter = (journeyAdmin: boolean) => {
    const userRoute = AllScreens(journeyAdmin)
    return createBrowserRouter(
        createRoutesFromElements(
            <Route
                errorElement={
                    <ApplicationLayout items={userRoute}>
                        <NotFound />
                    </ApplicationLayout>
                }>
                {userRoute.map((scr) => (
                    <Route
                        key={scr.name}
                        path={scr.path}
                        element={
                            <ApplicationLayout items={userRoute} current={scr}>
                                <scr.component title={scr.title} />
                            </ApplicationLayout>
                        }
                        errorElement={
                            <ApplicationLayout items={userRoute} current={scr}>
                                <NotFound />
                            </ApplicationLayout>
                        }
                    />
                ))}
                <Route key={'snake'} path={'/snake'} element={<SnakeGame />} />
            </Route>
        )
    )
}

const journeySelectionRouter = createBrowserRouter(
    createRoutesFromElements(
        <Route errorElement={<NotFound />}>
            {JourneySelectorScreen.map((scr) => (
                <Route
                    key={scr.name}
                    path={scr.path}
                    element={<scr.component />}
                    errorElement={<NotFound />}
                />
            ))}
        </Route>
    )
)

const App = () => {
    const webservices = useApi()

    const { token, me, user } = useReducer(AccountStore.store, (s) => s)
    const { selected } = useReducer(JourneyStore.store, (s) => s)

    React.useEffect(() => {
        // @ts-ignore
        if (window.screen.orientation && window.screen.orientation.lock) {
            // @ts-ignore
            window.screen.orientation.lock('portrait').catch((err) => {
                console.error("Erreur lors du verrouillage de l'orientation :", err)
            })
        }
    }, [])

    React.useEffect(() => {
        if (token && webservices) {
            webservices.users
                .me()
                .then((me) => {
                    ;(me.admin ? webservices.journeys.list : webservices.users.getJourneys)()
                        .then((journeys) => {
                            JourneyStore.actions.setJourneys(journeys)
                            AccountStore.actions.setMe(me)
                        })
                        .catch(ErrorLogger)
                })
                .catch(ErrorLogger)
        }
    }, [webservices])

    if (token) {
        if (!selected) {
            return <RouterProvider router={journeySelectionRouter} />
        } else {
            return <RouterProvider router={authRouter(!!me?.admin || !!user?.admin)} />
        }
    } else {
        return <RouterProvider router={loginRouter} />
    }
}

export default App
