// Exemple d'utilisation
import * as React from 'react'

import useApi from '../utils/webservices/useApi'
import useReducer from '../utils/store/useReducer'
import * as AccountStore from '../utils/store/account'

import styled from 'styled-components'
import { OUTER_SHADOW, SECONDARY_COLOR } from '../utils/constant'

import StatsComponent from '../components/StatsComponents'
import Loading from '../components/Loading'
import NoItem from '../components/NoItem'

const Stats = () => {
    const webservices = useApi()
    const { me } = useReducer(AccountStore.store, (s) => s)

    const [status, setStatus] = React.useState<ScreenStatus>('loading')
    const [users, setUsers] = React.useState<JourneyUser[]>([])
    const [stepRankings, setStepRankings] = React.useState<StepRanking[]>([])
    const [selectedUser, setSelectedUser] = React.useState<JourneyUser | undefined>(undefined)

    React.useEffect(() => {
        Promise.all([webservices.journeys.getRanking(), webservices.journeys.getUsers()])
            .then(([r, u]) => {
                setUsers(u)
                const selected = u.find((us) => us.id === me?.id)
                setStepRankings(r)
                setSelectedUser(selected)
                setStatus('fetched')
            })
            .catch(() => setStatus('error'))
    }, [])

    if (status === 'loading') {
        return <Loading type="transparent" />
    }

    if (users.length === 0) {
        return <NoItem />
    }

    if (!selectedUser || !stepRankings || stepRankings.length === 0) {
        return <NoItem />
    }

    return (
        <Container>
            <UserHeader>
                {users.map((user) => (
                    <UserContainer>
                        <UserPicture
                            src={user.picture}
                            selected={user.id === selectedUser.id}
                            onClick={() => setSelectedUser(user)}
                        />
                        <UserName>{user.firstName}</UserName>
                    </UserContainer>
                ))}
            </UserHeader>
            <StatsComponent playerId={selectedUser.id} stepRankings={stepRankings} />
        </Container>
    )
}

export default Stats

const PICTURE_SIZE = 60

const Container = styled.div``
const UserHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: scroll;
`
const UserContainer = styled.div`
    margin: 0 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justofy-content: center;
`
const UserPicture = styled.img<{ selected: boolean }>`
    ${(props) => props.selected && `border: 3px solid ${SECONDARY_COLOR};`}
    height: ${PICTURE_SIZE}px;
    width: ${PICTURE_SIZE}px;
    border-radius: 100%;
    ${OUTER_SHADOW}
    cursor: pointer;
`
const UserName = styled.div``
