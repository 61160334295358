import * as React from 'react'

import styled from 'styled-components'

import Input from '../Input'
import Answer from '../Answer'
import NoItem from '../NoItem'
import Button from '../Button'
import ErrorLogger from '../ErrorLogger'
import PictureInput from '../PictureInput'

import { Formik } from 'formik'
import { useAlert } from 'react-alert'
import useApi from '../../utils/webservices/useApi'

import useReducer from '../../utils/store/useReducer'
import * as AccountStore from '../../utils/store/account'
import { isMobile } from 'react-device-detect'

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

interface Props {
    challenges: Challenge[]
}

const StepChallenges = ({ challenges }: Props) => {
    return (
        <Container>
            <ContentList>
                {challenges.length === 0 ? (
                    <NoItem />
                ) : (
                    challenges.map((challenge) => <ChallengeCard challenge={challenge} />)
                )}
            </ContentList>
        </Container>
    )
}

export default StepChallenges

const ChallengeCard = ({ challenge }: { challenge: Challenge }) => {
    const Alert = useAlert()
    const webservices = useApi()

    const { user } = useReducer(AccountStore.store, (s) => s)

    const [loading, setLoading] = React.useState(false)
    const [isCollapsed, setCollapsed] = React.useState(true)
    const [answers, setAnswers] = React.useState<ChallengeResponse[]>([])

    React.useEffect(() => {
        webservices.challenges.getMyResponses(challenge.id).then(setAnswers).catch(ErrorLogger)
    }, [])

    if (!user) {
        return <NoItem />
    }

    return (
        <ContentCard key={challenge.id}>
            <Image src={challenge.picture || '/login_background.jpeg'} alt={challenge.title} />
            <ContentInfo>
                <ContentTitle>{challenge.title}</ContentTitle>
                <ContentDescription dangerouslySetInnerHTML={{ __html: challenge.description }} />
                {challenge.picture && (
                    <Zoom>
                        <FullImage src={challenge.picture} alt={challenge.title} />
                    </Zoom>
                )}
                {answers.length > 0 && (
                    <>
                        <ContentTitle>Mes Réponses</ContentTitle>

                        <AnswerList>
                            {answers.map((ans) => (
                                <Answer answer={ans} user={user} />
                            ))}
                        </AnswerList>
                    </>
                )}
                {isCollapsed ? (
                    <Footer>
                        <Button
                            label={answers.length > 0 ? 'Ajouter une autre réponse' : 'Répondre'}
                            type="primary"
                            status="active"
                            onclick={() => setCollapsed(false)}
                        />
                    </Footer>
                ) : (
                    <Formik<{ content: string; picture: string }>
                        initialValues={{ content: '', picture: '' }}
                        onSubmit={(data) => {
                            setLoading(true)
                            webservices.challenges
                                .createResponse(challenge.id, data)
                                .then(() => {
                                    Alert.success('Réponse envoyée !')
                                    setCollapsed(true)
                                    webservices.challenges
                                        .getMyResponses(challenge.id)
                                        .then(setAnswers)
                                        .catch(ErrorLogger)
                                        .finally(() => setLoading(false))
                                })
                                .catch(ErrorLogger)
                        }}>
                        {({ values, handleChange, handleSubmit, setFieldValue, dirty }) => (
                            <>
                                <Input
                                    label="Réponse"
                                    name="content"
                                    type="long-text"
                                    value={values.content}
                                    onChange={handleChange}
                                    placeholder="Réponse"
                                />
                                <PictureInput
                                    label="Photo de la réponse"
                                    value={values.picture}
                                    setValue={(v) => setFieldValue('picture', v)}
                                />
                                <Footer>
                                    <Button
                                        label="Annuler"
                                        type="secondary"
                                        status={loading ? 'disabled' : 'active'}
                                        onclick={() => setCollapsed(true)}
                                    />
                                    <Button
                                        label="Envoyer ma réponse"
                                        type="primary"
                                        status={loading ? 'loading' : dirty ? 'active' : 'disabled'}
                                        onclick={handleSubmit}
                                    />
                                </Footer>
                            </>
                        )}
                    </Formik>
                )}
            </ContentInfo>
        </ContentCard>
    )
}

const Container = styled.div`
    padding: 20px;
    background-color: #f5f5f5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
        padding: 15px;
    }
`
const ContentList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1200px;
`

const ContentCard = styled.div`
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
`

const Image = styled.img`
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: center top;
    filter: blur(4px);
`
const FullImage = styled.img`
    width: 100%;
    height: 300px;
    object-fit: contain;
`

const ContentInfo = styled.div`
    padding: 15px;
    text-align: left;
`

const ContentTitle = styled.h2`
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
`

const ContentDescription = styled.p`
    font-size: 1rem;
    color: #555;
`

const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    gap: 10px;
    ${isMobile &&
    `
        flex-direction: column;
        align-items: flex-end;
        `}
`

const AnswerCard = styled.div`
    background-color: white;
    min-width: 100px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    overflow: hidden;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    flex-stretch: 1;
    flex-shrink: 1;
    justify-content: center;
    align-items: center;
    img {
        max-width: 100px;
        max-height: 100px;
        object-fit: cover;
    }
`

const AnswerList = styled.div`
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`
